import styled from '@emotion/styled';
import { Chip } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { Message, FileUploaded } from '../core/chat/types';

export interface FilesProps {
  message: Pick<Message, 'attachments'>;
  del?: (uuid: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;

  label {
    cursor: default;
  }

  .fa-times {
    cursor: pointer;
    margin-left: 10px;
    color: #c1c2c5;
  }
  .fa-file {
    margin-right: 5px;
    color: #c1c2c5;
  }
`;

export function Files({ message, del }: FilesProps) {
  const list = useMemo(
    () =>
      (message.attachments || [])
        .map((v) => {
          if (FileUploaded.is(v)) {
            if (v.storFileDelete) return;
            return {
              uuid: v.uuid,
              name: v.name || v.data.file_id,
            };
          }
          return {
            uuid: v.uuid,
            name: v.source.name,
          };
        })
        .filter(Boolean) as {
        uuid: string;
        name: string;
      }[],
    [message],
  );

  const click = useCallback(
    (uuid: string) => {
      if (!del) return;
      del(uuid);
    },
    [del],
  );
  return (
    <Container className={'files'}>
      {list.map((a) => (
        <Chip key={a.uuid} checked={false}>
          <i
            color="black"
            className="fa fa-file"
            onClick={() => {
              click(a.uuid);
            }}
          />
          {a.name}
          {del && (
            <i
              color="black"
              className="fa fa-times"
              onClick={() => {
                click(a.uuid);
              }}
            />
          )}
        </Chip>
      ))}
    </Container>
  );
}
