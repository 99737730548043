import { SetStateAction } from 'react';
import { Chat, OpenAIMessage, Parameters } from '../chat/types';
import { OptionsManager } from '../options';

export interface PluginContext<
  T extends Record<string, any> = Record<string, any>,
> {
  getOptions(): T;
  getCurrentChat(): Chat;
  createChatCompletion(
    messages: OpenAIMessage[],
    parameters: Parameters,
  ): Promise<string>;
  setChatTitle(title: string): Promise<void>;
  setOptions<K extends string>(optionID: K, v: SetStateAction<T[K]>): void;
}

export function createBasicPluginContext(
  pluginID: string,
  pluginOptions: OptionsManager,
  chatID?: string | null,
  chat?: Chat | null,
) {
  return {
    getOptions: (_pluginID = pluginID) =>
      pluginOptions.getAllOptions(_pluginID, chatID),
    getCurrentChat: () => chat,
    createChatCompletion: async () => '',
    setChatTitle: async (title: string) => {},
    setOptions: (optionID: string, v: SetStateAction<any>) => {
      if (typeof v === 'function') {
        const newV = (v as any)(
          pluginOptions.getValidatedOption(pluginID, optionID, chatID),
        );
        pluginOptions.setOption(pluginID, optionID, newV, chatID);
      } else return pluginOptions.setOption(pluginID, optionID, v, chatID);
    },
  } as PluginContext;
}
