export interface AppConfig {
  ONLY_DEFAULT_MODEL?: boolean;
  ONLY_THREAD?: boolean;
  STRICT_OPENAI_API_KEY?: string;
  STRICT_SERP_API_KEY?: string;
  MEMORY_PROMPT: string;
  SYSTEM_PROMPT: string;
  BACK: string;
}

export const AppConfig = {} as { config?: AppConfig };

export async function loadAppConfig() {
  const response = await fetch(`/config.json`);

  if (!response.ok) return;
  const ret: AppConfig = await response.json();
  AppConfig.config = ret;
  return ret;
}
