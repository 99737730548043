import styled from '@emotion/styled';
import { Alert, Button, Loader, MANTINE_COLORS } from '@mantine/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../store';
import { Page } from '../page';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../core/authContext';

const Container = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.7;
  gap: 1rem;
`;

export default function LandingPage(props: any) {
  const openAIApiKey = localStorage.getItem('openai-api-key');
  const dispatch = useAppDispatch();
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader color="#B8860B" variant="dots" />
      </div>
    );
  }

  if (!auth.user && !auth.isLoading) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Page id={'landing'} showSubHeader={true}>
      <Container>
        <p style={{ color: 'black' }}>
          <FormattedMessage
            defaultMessage={'Hello, how can I help you today?'}
            description="A friendly message that appears at the start of new chat sessions"
          />
        </p>
        {!openAIApiKey && (
          <Alert color={MANTINE_COLORS[2]}>
            You don't have API key. Ask Admin to add an API key for you.
          </Alert>
        )}
      </Container>
    </Page>
  );
}
