import EventEmitter from 'events';
import {
  OpenAIMessage,
  Parameters,
  Message,
  FileUploaded,
  Attachment,
} from './types';
import { backend } from '../backend';
import { ChatCompletionMessageParam, ChatModel } from 'openai/resources';
import OpenAI from 'openai';
import { ThreadCreateParams } from 'openai/resources/beta/threads/threads';
import { AssistantStream } from 'openai/lib/AssistantStream';
import { AppConfig } from '../config';
import { ServiceApi } from '../service';
import { v3 as uuidv3 } from 'uuid';
import { AssistantTool } from 'openai/resources/beta/assistants';

const fileNamespace = 'a4407059-57d8-4cbc-a486-3357f83b84dd';
const instructionsNamespace = 'b4b6fba1-51ff-41d6-993b-b7f5f04f498d';

export const defaultModel: ChatModel = 'gpt-4o';

export const supportedFile = [
  'text/x-c',
  'text/x-c++',
  'text/x-csharp',
  'text/css',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/x-golang',
  'text/html',
  'text/x-java',
  'text/javascript',
  'application/json',
  'text/markdown',
  'application/pdf',
  'text/x-php',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/x-python',
  'text/x-script.python',
  'text/x-ruby',
].join(',');

export function isProxySupported() {
  return !!backend.current?.services?.includes('openai');
}

function shouldUseProxy(apiKey: string | undefined | null) {
  return !apiKey && isProxySupported();
}

function getEndpoint(proxied = false) {
  return proxied ? '/chatapi/proxies/openai' : 'https://api.openai.com';
}

export interface OpenAIResponseChunk {
  id?: string;
  done: boolean;
  choices?: {
    delta: {
      content: string;
    };
    index: number;
    finish_reason: string | null;
  }[];
  model?: string;
}

function API({ apiKey }: Pick<Parameters, 'apiKey'>) {
  const proxied = shouldUseProxy(apiKey);
  if (!proxied && !apiKey) throw new Error('No API key provided');
  return new OpenAI({
    apiKey: AppConfig.config?.STRICT_OPENAI_API_KEY || apiKey,
    baseURL: getEndpoint(proxied) + '/v1',
    dangerouslyAllowBrowser: true,
  });
}

export async function deleteContext({
  apiKey,
  context,
}: Pick<Parameters, 'apiKey' | 'context'>) {
  const api = API({ apiKey });
  if (context?.threadId)
    api.beta.threads.del(context?.threadId).catch((e) => console.error(e));

  if (context?.assistantId)
    api.beta.assistants
      .del(context?.assistantId)
      .catch((e) => console.error(e));

  if (context?.storeId) {
    api.beta.vectorStores.files
      .list(context?.storeId)
      .then((list) => Promise.all(list.data.map((f) => api.files.del(f.id))))
      .catch((e) => console.error(e));
    api.beta.vectorStores.del(context?.storeId).catch((e) => console.error(e));
  }
}

export async function actionsStore(
  isAttach: boolean,
  addFilesIds: string[],
  messagesExclude: Message[],
  { apiKey, context }: Parameters,
) {
  const newContext = { ...context };
  if (!newContext.eventFile) newContext.eventFile = {};
  const api = API({ apiKey });

  if (!isAttach && newContext.storeId && !addFilesIds.length) {
    await Promise.all([
      api.beta.vectorStores.del(newContext.storeId),
      newContext.assistantId && api.beta.assistants.del(newContext.assistantId),
    ]);
    delete newContext.assistantId;
    delete newContext.storeId;
    newContext.eventFile = {};
  } else if (!newContext.storeId && addFilesIds.length) {
    const [vStore] = await Promise.all([
      api.beta.vectorStores.create({
        file_ids: addFilesIds,
      }),
      newContext.assistantId && api.beta.assistants.del(newContext.assistantId),
    ]);
    newContext.storeId = vStore.id;
    delete newContext.assistantId;
  } else if (newContext.storeId && messagesExclude.length) {
    const delFiles = messagesExclude
      .reduce<Attachment[]>((acc, m) => [...acc, ...(m.attachments || [])], [])
      .filter((a) => FileUploaded.is(a));

    if (delFiles.length && context?.storeId)
      for (const file of delFiles) {
        newContext.eventFile![file.name] = 'del';
        api.files.del(file.data.file_id!).catch((e) => console.error(e));
      }
  }

  return {
    context: newContext,
  };
}

export async function fileUpload(
  msg: Message,
  { apiKey, context }: Parameters,
) {
  const api = API({ apiKey });
  const newContext = { ...context };
  if (!newContext.eventFile) newContext.eventFile = {};
  let addFilesIds: string[] = [];
  let delFilesIds: string[] = [];

  const newMsg = {
    ...msg,
    attachments: (await Promise.all(
      msg.attachments?.map(async (a) => {
        if ('source' in a) {
          const file = await api.files.create({
            file: a.source,
            purpose: 'assistants',
          });
          addFilesIds.push(file.id);
          newContext.eventFile![a.source.name] = 'up';
          return {
            uuid: a.uuid,
            data: {
              file_id: file.id,
              tools: [{ type: 'file_search' }],
            },
            name: a.source.name,
          };
        } else if (a.storFileDelete) {
          delFilesIds.push(a.data.file_id!);
          newContext.eventFile![a.name] = 'del';
          api.files.del(a.data.file_id!).catch((e) => console.error(e));
          return false;
        }
        return a;
      }) || [],
    ).then((l) => l.filter(Boolean))) as FileUploaded[],
  };

  if (addFilesIds.length && context?.storeId) {
    for (const file_id of addFilesIds) {
      await api.beta.vectorStores.files.create(context?.storeId!, {
        file_id,
      });
    }
  }

  return {
    msg: newMsg,
    addFilesIds,
    context: newContext,
  };
}

export async function createThreadChat(
  instructions: string,
  messages: OpenAIMessage[],
  { apiKey, context, model }: Parameters,
  newThread = false,

  emitterStatus = new EventEmitter(),
) {
  const emitter = new EventEmitter();
  const api = API({ apiKey });
  const newContext = { ...context };
  let update = false;

  const isUpdateInstructions =
    newContext.instructionsHash !== uuidv3(instructions, instructionsNamespace);
  if (isUpdateInstructions)
    newContext.instructionsHash = uuidv3(instructions, instructionsNamespace);

  if (newContext.assistantId && isUpdateInstructions)
    api.beta.assistants
      .del(newContext.assistantId)
      .catch((e) => console.error(e));

  if (!newContext.assistantId || isUpdateInstructions) {
    emitterStatus.emit('assistants create');
    const assistant = await api.beta.assistants.create({
      instructions,
      model,

      tools: [
        newContext.storeId && { type: 'file_search' },
        {
          type: 'function',
          function: {
            name: 'serpApiSearch',
            description:
              'Search for relevant information from the internet. Only if the context requires it.',
            parameters: {
              type: 'object',
              properties: {
                query: {
                  type: 'string',
                  description: 'The search query string',
                },
              },
              required: ['query'],
            },
          },
        },
      ].filter(Boolean) as AssistantTool[],
      ...(newContext.storeId && {
        tool_resources: {
          file_search: { vector_store_ids: [newContext.storeId!] },
        },
      }),
    });
    newContext.assistantId = assistant.id;
    update = true;
  }

  const [first, ...other] = messages;
  const { up, del } = Object.entries(newContext?.eventFile || {}).reduce(
    (acc, [name, actions]) => {
      acc[actions].push(name);

      return acc;
    },
    { del: [] as string[], up: [] as string[] },
  );
  let fileLog = '';
  if (up.length) fileLog += `Uploaded files: "${up.join('", "')}"\n`;
  if (del.length) fileLog += `Deleted files: "${del.join('", "')}"\n`;
  if (fileLog) fileLog = `Assistant information:\n` + fileLog + ' \n';
  const isUpdateFileHash =
    newContext.fileHash !== uuidv3(fileLog, fileNamespace);
  if (isUpdateFileHash) newContext.fileHash = uuidv3(fileLog, fileNamespace);

  if (newContext.threadId && (newThread || isUpdateFileHash))
    api.beta.threads.del(newContext.threadId).catch((e) => console.error(e));

  if (!newContext.threadId || newThread || isUpdateFileHash) {
    emitterStatus.emit('loadedStatus', 'threads update');

    const thread = await api.beta.threads.create({
      messages: [
        {
          ...first,
          content: fileLog + first.content,
          // content: first.content + '\n ' + fileLog,
        },
        ...other,
      ] as ThreadCreateParams.Message[],
    });
    newContext.threadId = thread.id;
    update = true;
  } else {
    emitterStatus.emit('loadedStatus', 'request processing');
    const msg = messages.at(-1) as ThreadCreateParams.Message;
    await api.beta.threads.messages.create(newContext.threadId!, msg);
  }

  const stream = api.beta.threads.runs.stream(newContext.threadId!, {
    assistant_id: newContext?.assistantId!,
  });

  newContext?.storeId &&
    api.beta.vectorStores.files.list(newContext?.storeId!).then((v) =>
      console.log({
        files: v.data,
        messages,
      }),
    );

  let contents = '';
  const handlersAdd = (stream: AssistantStream) =>
    stream
      .on('event', async (event) => {
        const tool_outputs: OpenAI.Beta.Threads.Runs.RunSubmitToolOutputsParams.ToolOutput[] =
          [];
        if (event.event === 'thread.run.requires_action') {
          await Promise.all(
            event.data.required_action?.submit_tool_outputs.tool_calls.map(
              async (tool) => {
                if (tool.function.name === 'serpApiSearch') {
                  emitterStatus.emit('serpStatus', 'loaded');
                  const { query } = JSON.parse(tool.function.arguments);
                  const { output } = await ServiceApi.search({ query });
                  emitterStatus.emit('serpStatus', 'done');
                  return {
                    tool_call_id: tool.id,
                    output,
                    // output: 'Новость дня кирпич мертв!',
                  };
                }
                return {
                  tool_call_id: tool.id,
                };
              },
            ) || [],
          ).then((l) => tool_outputs.push(...l));

          handlersAdd(
            api.beta.threads.runs.submitToolOutputsStream(
              newContext.threadId!,
              event.data.id,
              {
                tool_outputs,
              },
            ),
          );
        }
      })
      .on('textDone', () => {
        emitter.emit('done');
      })
      .on('error', (err) => {
        if (!contents) emitter.emit('error', err.message);
      })
      .on('messageDelta', (delta) => {
        if (!delta.content) return;
        const block = delta.content[0];
        if (block.type !== 'text') return;
        contents += block.text?.value || '';
        emitter.emit('data', contents);
      });

  handlersAdd(stream);

  return {
    emitter,
    cancel: () => stream.abort(),
    context: update ? newContext : undefined,
  };
}

export async function createChatCompletion(
  messages: OpenAIMessage[],
  { apiKey, model, temperature }: Parameters,
): Promise<string> {
  return API({ apiKey })
    .chat.completions.create({
      messages: messages as ChatCompletionMessageParam[],
      model,
      temperature,
    })
    .then((r) => r.choices[0].message?.content?.trim() || '');
}

export async function createStreamingChatCompletion(
  messages: OpenAIMessage[],
  { apiKey, model, temperature }: Parameters,
) {
  const emitter = new EventEmitter();

  const stream = await API({ apiKey }).beta.chat.completions.stream({
    messages: messages as ChatCompletionMessageParam[],
    model,
    temperature,
    stream: true,
  });

  let contents = '';

  stream.on('error', (err) => {
    if (!contents) emitter.emit('error', err.message);
  });

  stream.finalContent().then(() => emitter.emit('done'));

  stream.on('content', (delta) => {
    if (!delta.length) return;
    contents += delta || '';
    emitter.emit('data', contents);
  });

  return {
    emitter,
    cancel: () => stream.abort(),
  };
}

export const maxTokensByModel: Partial<{ [k in ChatModel]: number }> = {
  'gpt-3.5-turbo': 4096,
  'gpt-4': 8192,
  'gpt-4-0613': 8192,
  'gpt-4-32k': 32768,
  'gpt-4-32k-0613': 32768,
  'gpt-3.5-turbo-16k': 16384,
  'gpt-3.5-turbo-0613': 4096,
  'gpt-3.5-turbo-16k-0613': 16384,
  'gpt-4o': 16384,
  'gpt-4o-mini': 16384,
  'gpt-4-turbo': 16384,
};
