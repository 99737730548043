import styled from '@emotion/styled';
import {
  Button,
  Drawer,
  Table,
  Textarea,
  Text,
  MANTINE_COLORS,
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeMemoryUI, selectMemoryOptions } from '../../store/memory';
import { useOption } from '../../core/options/use-option';
import { MemoryPluginOptions } from '../../plugins/memory';
import { v4 as uuidv4 } from 'uuid';
import { useModals } from '@mantine/modals';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  padding: 0.4rem 1rem 1rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;

  @media (max-width: 40em) {
    padding: 0;
  }

  table {
    width: 100%;
    display: block;
    thead {
      display: block;
    }
    tbody {
      display: block;
    }
    tr {
      & > .m90 {
        min-width: 80px;
      }
      display: flex;
      justify-content: space-evenly;
      & .gr {
        flex-grow: 1;
        max-width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      th {
        max-width: 100%;
      }
    }
  }
`;

function CreateModel({ actions }: { actions: (newVal: string) => void }) {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (value.length < 5) return setError('Minimum length 5 characters');

    setError('');
  }, [value]);

  return (
    <div>
      <Textarea
        id="chat-title"
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        maxLength={3000}
        minLength={5}
        autosize
        required
        error={error}
      />
      <Button
        fullWidth
        variant="light"
        style={{ marginTop: '1rem' }}
        onClick={() => {
          if (error) return;
          actions(value);
        }}
      >
        <FormattedMessage
          defaultMessage="Create"
          description="Label for the button used to memory create"
        />
      </Button>
    </div>
  );
}

export default function MemoryDrawer() {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectMemoryOptions);
  const close = useCallback(() => dispatch(closeMemoryUI()), [dispatch]);
  const modals = useModals();

  const [list, setList] = useOption<MemoryPluginOptions['list']>(
    'memory',
    'list',
  );

  const add = useCallback(() => {
    modals.openModal({
      title: 'New record',
      children: (
        <CreateModel
          actions={(value) => {
            setList((l) => [
              ...l,
              {
                uuid: uuidv4(),
                timestamp: Date.now(),
                value,
              },
            ]);
            modals.closeAll();
          }}
        />
      ),
    });
  }, []);

  const del = useCallback((uuid: string) => {
    setList((l) => l.filter((i) => i.uuid !== uuid));
  }, []);

  const rows = useMemo(
    () =>
      list
        .filter((i) => Boolean(i.value))
        .map((item, i) => {
          const [date, time] = new Date(item.timestamp)
            .toLocaleString()
            .split(',');

          return (
            <tr key={item.uuid + i}>
              <td className="m90">
                <div>{date}</div>
                <div>{time}</div>
              </td>
              <td className="gr">{item.value}</td>
              <td>
                <Button
                  variant="outline"
                  color={MANTINE_COLORS[1]}
                  onClick={() => {
                    del(item.uuid);
                  }}
                >
                  <i className={'fa fa-trash'} />
                </Button>
              </td>
            </tr>
          );
        }),
    [list],
  );

  return (
    <Drawer
      size="50rem"
      position="right"
      opened={isOpen}
      onClose={close}
      transition="slide-left"
      transitionDuration={200}
      withCloseButton={false}
    >
      <Container style={{ backgroundColor: 'rgb(69, 85, 80)' }}>
        <Text>Memory</Text>
        <Table>
          <thead>
            <tr>
              <th className="m90">
                {' '}
                <FormattedMessage
                  defaultMessage="Created"
                  description="Label for the button used to memory header created"
                />
              </th>
              <th className="gr">
                {' '}
                <FormattedMessage
                  defaultMessage="Value"
                  description="Label for the button used to memory header value"
                />
              </th>
              <th>
                <Button
                  onClick={() => add()}
                  color={MANTINE_COLORS[1]}
                  variant="filled"
                >
                  <i className={'fa fa-plus'} />
                  <span>
                    {' '}
                    <FormattedMessage
                      defaultMessage="Add"
                      description="Label for the button used to add memory"
                    />
                  </span>
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Container>
    </Drawer>
  );
}
