import styled from '@emotion/styled';
import { Button, ActionIcon, Textarea, Loader, Popover } from '@mantine/core';
import { getHotkeyHandler, useMediaQuery } from '@mantine/hooks';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../core/context';
import { useAppDispatch, useAppSelector } from '../store';
import { selectMessage, setMessage } from '../store/message';
import { selectSettingsTab, openOpenAIApiKeyPanel } from '../store/settings-ui';
import {
  speechRecognition,
  supportsSpeechRecognition,
} from '../core/speech-recognition-types';
import { useWhisper } from '@chengsokdara/use-whisper';
import QuickSettings from './quick-settings';
import { useOption } from '../core/options/use-option';
import { useAuth } from '../core/authContext';
import { Attachment } from '../core/chat/types';
import { v4 as uuidv4 } from 'uuid';
import { Files } from './files';
import { supportedFile } from '../core/chat/openai';
import { useDropzone } from 'react-dropzone';

const Container = styled.div`
  background: white;
  padding: 1rem 1rem 0 1rem;
  gap: 10px;
  position: relative;

  .files {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .inner {
    max-width: 50rem;
    margin: auto;
    text-align: right;
  }

  .settings-button {
    margin: 0.5rem -0.4rem 0.5rem 1rem;
    font-size: 0.7rem;
    color: white;
  }

  .mantine-1i0vqid {
    background-color: white;
    color: black;

    &:focus {
      border-color: #b8860b;
    }
  }

  .mantine-Textarea-disabled {
    background: #f7f7f7;
  }

  .fa-paper-plane,
  .fa-file,
  .fa-microchip {
    color: black;
  }

  .dg {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    background-color: #25262b85;
  }
`;

export declare type OnSubmit = (name?: string) => Promise<boolean>;

export interface MessageInputProps {
  disabled?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
  const message = useAppSelector(selectMessage);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [recording, setRecording] = useState(false);
  const [speechError, setSpeechError] = useState<string | null>(null);
  const hasVerticalSpace = useMediaQuery('(min-height: 1000px)');
  const [useOpenAIWhisper] = useOption<boolean>(
    'speech-recognition',
    'use-whisper',
  );
  const [openAIApiKey] = useOption<string>('openai', 'apiKey');

  const [initialMessage, setInitialMessage] = useState('');
  const { transcribing, transcript, startRecording, stopRecording } =
    useWhisper({
      apiKey: openAIApiKey || ' ',
      streaming: false,
    });

  const navigate = useNavigate();
  const context = useAppContext();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const tab = useAppSelector(selectSettingsTab);

  const [showMicrophoneButton] = useOption<boolean>(
    'speech-recognition',
    'show-microphone',
  );
  const [submitOnEnter] = useOption<boolean>('input', 'submit-on-enter');
  const userAuth = useAuth();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(setMessage(e.target.value));
    },
    [dispatch],
  );

  const pathname = useLocation().pathname;

  const memoryRunAll = useCallback(async () => {
    await context.memoryRunAll();
  }, [context]);

  const onSubmit = useCallback(async () => {
    setSpeechError(null);

    const chatId = await context.onNewMessage(message, attachments);

    if (chatId) {
      if (!window.location.pathname.includes(chatId)) {
        navigate('/chat/' + chatId);
      }
      setAttachments([]);
      dispatch(setMessage(''));
    }
  }, [context, message, attachments, dispatch, navigate]);

  const onSpeechError = useCallback(
    (e: any) => {
      console.error('speech recognition error', e);
      setSpeechError(e.message);

      try {
        speechRecognition?.stop();
      } catch (e) {}

      try {
        stopRecording();
      } catch (e) {}

      setRecording(false);
    },
    [stopRecording],
  );

  const onHideSpeechError = useCallback(() => setSpeechError(null), []);

  const onSpeechStart = useCallback(async () => {
    let granted = false;
    let denied = false;

    try {
      const result = await navigator.permissions.query({
        name: 'microphone' as any,
      });
      if (result.state == 'granted') {
        granted = true;
      } else if (result.state == 'denied') {
        denied = true;
      }
    } catch (e) {}

    if (!granted && !denied) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        granted = true;
      } catch (e) {
        denied = true;
      }
    }

    if (denied) {
      onSpeechError(new Error('speech permission was not granted'));
      return;
    }

    try {
      if (!recording) {
        setRecording(true);

        if (useOpenAIWhisper || !supportsSpeechRecognition) {
          if (!openAIApiKey) {
            dispatch(openOpenAIApiKeyPanel());
            return false;
          }
          // recorder.start().catch(onSpeechError);
          setInitialMessage(message);
          await startRecording();
        } else if (speechRecognition) {
          const initialMessage = message;

          speechRecognition.continuous = true;
          speechRecognition.interimResults = true;

          speechRecognition.onresult = (event) => {
            let transcript = '';
            for (let i = 0; i < event.results.length; i++) {
              if (event.results[i].isFinal && event.results[i][0].confidence) {
                transcript += event.results[i][0].transcript;
              }
            }
            dispatch(setMessage(initialMessage + ' ' + transcript));
          };

          speechRecognition.start();
        } else {
          onSpeechError(new Error('not supported'));
        }
      } else {
        if (useOpenAIWhisper || !supportsSpeechRecognition) {
          await stopRecording();
          setTimeout(() => setRecording(false), 500);
        } else if (speechRecognition) {
          speechRecognition.stop();
          setRecording(false);
        } else {
          onSpeechError(new Error('not supported'));
        }
      }
    } catch (e) {
      onSpeechError(e);
    }
  }, [
    recording,
    message,
    dispatch,
    onSpeechError,
    setInitialMessage,
    openAIApiKey,
  ]);

  useEffect(() => {
    if (useOpenAIWhisper || !supportsSpeechRecognition) {
      if (!transcribing && !recording && transcript?.text) {
        dispatch(setMessage(initialMessage + ' ' + transcript.text));
      }
    }
  }, [
    initialMessage,
    transcript,
    recording,
    transcribing,
    useOpenAIWhisper,
    dispatch,
  ]);

  const blur = useCallback(() => {
    document.querySelector<HTMLTextAreaElement>('#message-input')?.blur();
  }, []);

  const deleteFile = useCallback((uuid: string) => {
    setAttachments((files) => files.filter((f) => f.uuid !== uuid));
  }, []);

  const onDrop = useCallback((files: File[]) => {
    if (!files?.length) return;
    setAttachments((att) => [
      ...att,
      ...files.map(
        (source) =>
          ({
            uuid: uuidv4(),
            source,
          }) as const,
      ),
    ]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    noClick: true,
    onDrop,
    useFsAccessApi: false,
  });

  const rightSection = useMemo(() => {
    return (
      <div
        style={{
          opacity: '0.8',
          paddingRight: '0.5rem',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {context.generating && (
          <>
            <Button
              style={{ backgroundColor: 'white', color: 'black' }}
              variant="subtle"
              size="xs"
              compact
              onClick={() => {
                context.chat.cancelReply(context.currentChat.leaf!.id);
              }}
            >
              <FormattedMessage
                defaultMessage={'Cancel'}
                description="Label for the button that can be clicked while the AI is generating a response to cancel generation"
              />
            </Button>
            <Loader
              color="#B8860B"
              size="xs"
              style={{ padding: '0 0.8rem 0 0.5rem' }}
            />
          </>
        )}
        {!context.generating && (
          <>
            {showMicrophoneButton && (
              <Popover
                width={200}
                position="bottom"
                withArrow
                shadow="md"
                opened={speechError !== null}
              >
                <Popover.Target>
                  <ActionIcon size="xl" onClick={onSpeechStart}>
                    {transcribing && <Loader color="#B8860B" size="xs" />}
                    {!transcribing && !recording && (
                      <i
                        className="fa fa-microphone"
                        style={{ fontSize: '90%', color: 'black' }}
                      />
                    )}
                    {!transcribing && recording && (
                      <i
                        className="fa fa-record-vinyl"
                        style={{ fontSize: '90%', color: 'red' }}
                      />
                    )}
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p
                      style={{
                        fontFamily: `"Work Sans", sans-serif`,
                        fontSize: '0.9rem',
                        textAlign: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Sorry, an error occured trying to record audio.
                    </p>
                    <Button
                      variant="light"
                      size="xs"
                      fullWidth
                      onClick={onHideSpeechError}
                    >
                      Close
                    </Button>
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
            <input {...getInputProps()} accept={supportedFile} />
            <ActionIcon
              size="xl"
              onClick={(e) => {
                inputRef.current?.click();
              }}
            >
              <i color="black" className="fa fa-file" />
            </ActionIcon>
            <ActionIcon size="xl" onClick={onSubmit}>
              <i
                color="black"
                className="fa fa-paper-plane"
                style={{ fontSize: '90%' }}
              />
            </ActionIcon>
            {userAuth?.roles?.includes('dev') && (
              <ActionIcon size="xl" onClick={memoryRunAll}>
                <i
                  color="black"
                  className="fa fa-microchip"
                  style={{ fontSize: '90%' }}
                />
              </ActionIcon>
            )}
          </>
        )}
      </div>
    );
  }, [
    recording,
    transcribing,
    onSubmit,
    memoryRunAll,
    onSpeechStart,
    props.disabled,
    context.generating,
    speechError,
    onHideSpeechError,
    showMicrophoneButton,
    getInputProps,
    inputRef,
  ]);

  const disabled = context.generating;

  const isLandingPage = pathname === '/';
  if (context.isShare || (!isLandingPage && !context.id)) {
    return null;
  }

  const hotkeyHandler = useMemo(() => {
    const keys = [
      ['Escape', blur, { preventDefault: true }],
      ['ctrl+Enter', onSubmit, { preventDefault: true }],
    ];
    if (submitOnEnter) {
      keys.unshift(['Enter', onSubmit, { preventDefault: true }]);
    }
    const handler = getHotkeyHandler(keys as any);
    return handler;
  }, [onSubmit, blur, submitOnEnter]);

  return (
    <Container style={{ backgroundColor: 'white' }} {...getRootProps()}>
      {isDragActive && <div className="dg">Drop some files here ...</div>}
      <Files message={{ attachments }} del={deleteFile} />
      <div className="inner">
        <Textarea
          className="spacer"
          disabled={props.disabled || disabled}
          id="message-input"
          autosize
          minRows={hasVerticalSpace || context.isHome ? 3 : 2}
          style={{ marginBottom: 10 }}
          maxRows={12}
          placeholder={intl.formatMessage({
            defaultMessage: 'Enter a message here...',
          })}
          value={message || ''}
          onChange={onChange}
          rightSection={rightSection}
          rightSectionWidth={context.generating ? 100 : 55}
          onKeyDown={hotkeyHandler}
        />
        {userAuth?.roles?.includes('admin') && <QuickSettings key={tab} />}
      </div>
    </Container>
  );
}
